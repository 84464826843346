import React from "react"
import Layout from "../layouts/es"
import SEO from "../components/seo"
import { Link } from "gatsby"

const Page404 = (props) => {
    return <Layout location={props.location}>
        <SEO title="404: Not found" />

        <div className="page-404-container">
            <h1 className="page-404 display-1 font-weight-bold">404<span className="blink">_</span></h1>
            <h3 className="text-center font-size-xl text-black mb-0 font-weight-bold spacing">Disculpa, pero no hemos encontrado esta página.</h3>

            <Link to={"/es"} className="btn btn-tecalis mt-3 spacing">
                Volver al inicio
            </Link>
        </div>
    </Layout>
}

export default Page404
